package uk.co.pogchampions.common.data

import kotlinx.serialization.Serializable
import uk.co.pogchampions.common.actor.ActorState

@Serializable
data class SpriteFrameDescription(
    val state: ActorState,
    val loopMode: LoopMode = LoopMode.Repeat,
    val up: Array<Int> = emptyArray(),
    val down: Array<Int> = emptyArray(),
    val left: Array<Int> = emptyArray(),
    val right: Array<Int> = emptyArray(),
    val downLeft: Array<Int> = emptyArray(),
    val downRight: Array<Int> = emptyArray(),
    val upLeft: Array<Int> = emptyArray(),
    val upRight: Array<Int> = emptyArray()
)

@Serializable
data class SpriteDescription(
    val image: String,
    val cellWidth: Int,
    val cellHeight: Int,
    val frames: Array<SpriteFrameDescription>
)

