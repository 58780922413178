package uk.co.pogchampions.game.comms

import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

import org.w3c.dom.WebSocket
import uk.co.pogchampions.common.dto.*
import uk.co.pogchampions.common.logging.GameLog

class WebSocketPogChampionsComms(private val websocketUrl: String) : PogChampionsComms {
    private val pendingEvents = mutableListOf<GameEvent>()
    private lateinit var socket: WebSocket
    override var onGameEvent: (GameEvent) -> Unit = { pendingEvents.add(it) }
        set(value) {
            field = value
            pendingEvents.forEach(field)
            pendingEvents.clear()
        }
    private var latestGameState = GameState(0, "", emptyList(), emptyList())
    private var inGame: Boolean = false

    private var successCallback: () -> Unit = {}
    private var failureCallback: () -> Unit = {}

    override var onMapChange: (String) -> Unit = {}
    override var onDialogue: (String, String?) -> Unit = { _, _ -> }

    override fun connect() {
        socket = WebSocket(websocketUrl).apply {
            onopen = {
                onGameEvent(GameEvent.ConnectionEstablished)
            }

            onmessage = {
                val message: MessageWrapper = Json.decodeFromString(it.data.toString())

                when (message.messageType) {
                    "SIGN_IN_SUCCESS" -> {
                        inGame = true
                        successCallback()
                    }
                    "SIGN_IN_FAILURE" -> {
                        failureCallback()
                    }
                    "MAP_CHANGE" -> {
                        onMapChange((message.payload as MapChangeIncoming).mapUrl)
                    }
                    "DIALOGUE_EVENT" -> {
                        val dialogueEventIncoming = message.payload as DialogueEventIncoming
                        onDialogue(dialogueEventIncoming.text, dialogueEventIncoming.portrait)
                    }
                    "PLAYER_STATES" -> {
                        latestGameState = message.payload as GameState
                    }
                }
            }

            onclose = {
                inGame = false
                onGameEvent(GameEvent.ConnectionLost)
            }
        }
    }

    override fun signIn(username: String, password: String, success: () -> Unit, failure: () -> Unit) {
        successCallback = success
        failureCallback = failure
        socket.send(Json.encodeToString(MessageWrapper("SIGN_IN", SignInRequest(username, password))))
    }

    override fun send(playerInputState: PlayerInputState) {
        if (socket.readyState == WebSocket.OPEN && inGame) {
            socket.send(Json.encodeToString(MessageWrapper("PLAYER_INPUT", playerInputState)))
        }
    }

    override fun latestGameState(): GameState {
        return latestGameState
    }
}