package uk.co.pogchampions.common.map

sealed interface MapObjects {
    val x: Double
    val y: Double
    val w: Double
    val h: Double

    val r: Double
        get() = x + w

    val b: Double
        get() = y + h

    fun pointInside(pX: Double, pY: Double): Boolean {
        return pX in x..r && pY in y .. b
    }

    data class TeleporterObject(
        override val x: Double,
        override val y: Double,
        override val w: Double,
        override val h: Double,
        val destination: String,
        val positionX: Int,
        val positionY: Int
    ) : MapObjects

    data class DialogueSource(
        override val x: Double,
        override val y: Double,
        override val w: Double,
        override val h: Double,
        val dialogue: String,
        val portrait: String?
    ) : MapObjects

    data class ActorScriptObject(
        override val x: Double,
        override val y: Double,
        val scriptFile: String,
        override val w: Double = 0.0,
        override val h: Double = 0.0
    ) : MapObjects
}