package uk.co.pogchampions.common.scripting

import uk.co.pogchampions.common.dto.RemoteActorState
import uk.co.pogchampions.common.map.MapEvent
import uk.co.pogchampions.common.map.PogChampionMap

class NpcScriptState(private val script: ActorScript, var state: RemoteActorState) {

    init {
        state = script.onLoad(state)
    }

    fun update(time: Double, playerStates: List<RemoteActorState>, map: PogChampionMap, onMapEvent: (MapEvent) -> Unit) {
        state = script.onUpdate(time, state, playerStates, map, onMapEvent)
    }

    fun use(playerState: RemoteActorState) {
        script.onUse(state, playerState)
    }

    fun attack(attackingPlayer: RemoteActorState) {
        script.onAttacked(state, attackingPlayer)
    }
}
