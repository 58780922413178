import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import uk.co.pogchampions.common.dto.PlayerInputState
import uk.co.pogchampions.engine.GameEngine
import uk.co.pogchampions.engine.UiInput
import uk.co.pogchampions.engine.dom.CanvasGraphics
import uk.co.pogchampions.engine.input.GameButtons
import uk.co.pogchampions.engine.input.GameInput
import uk.co.pogchampions.engine.input.WindowGameInput
import uk.co.pogchampions.game.comms.GameEvent
import uk.co.pogchampions.game.comms.WebSocketPogChampionsComms
import uk.co.pogchampions.game.comms.fake.FakePogChampionsComms
import uk.co.pogchampions.game.scenes.*


fun main() {
    val comms =
        if ((document.location.toString().contains("localhost") && !document.location.toString()
                .contains("?"))
        ) FakePogChampionsComms() else if (document.location.toString().contains("?local")) {
            WebSocketPogChampionsComms(
                "wss://pogchampions.co.uk/websocketlocal"
            )
        } else {
            WebSocketPogChampionsComms(
                "wss://pogchampions.co.uk/websocket"
            )
        }
    val canvas = document.getElementById("canvas") as HTMLCanvasElement
    val gameInput = WindowGameInput()

    UiInput.attachListeners(canvas)

    val graphics = CanvasGraphics(canvas.getContext("2d") as CanvasRenderingContext2D)

    lateinit var gameEngine: GameEngine

    val gameScene = GameScene(onPlayerDie = {
        window.setTimeout({ gameEngine.moveToScene("gameOver") }, 3000)
    })

    comms.onMapChange = { map ->
        gameScene.loadMap(map)
    }

    comms.onDialogue = { text, portrait ->
        gameScene.displayDialog(text, portrait)
    }

    gameEngine = GameEngine(
        graphics, gameInput, mapOf(
            "connecting" to ConnectingScene(),
            "splash" to PogChampionsSplash(onStart = {
                gameEngine.moveToScene("signIn")
            }),
            "signIn" to SignInScene(onSignInRequest = { username, password ->
                comms.signIn(username, password,
                    success = {
                        gameEngine.moveToScene("game")
                    },
                    failure = {
                        window.alert("Failed to sign in, were the credentials correct?")
                    })
            }),
            "game" to gameScene,
            "connectionError" to ConnectionErrorScene(),
            "gameOver" to GameOverScene()
        )
    )

    comms.onGameEvent = { event ->
        when (event) {
            GameEvent.ConnectionEstablished -> gameEngine.moveToScene("splash")
            GameEvent.ConnectionLost -> gameEngine.moveToScene("connectionError")
        }
    }

    comms.connect()

    fun queueAnimation(timestamp: Double) {
        graphics.updateBounds()
        gameEngine.update(timestamp)
        gameEngine.render()
        if (gameEngine.activeSceneName == "game") {
            gameScene.provideGameState(comms.latestGameState())
            comms.send(gameInput.toPlayerInputState())
        }
        window.requestAnimationFrame(::queueAnimation)
    }

    window.requestAnimationFrame(::queueAnimation)
}

fun GameInput.toPlayerInputState(): PlayerInputState {
    return PlayerInputState(
        isDown(GameButtons.Left),
        isDown(GameButtons.Right),
        isDown(GameButtons.Up),
        isDown(GameButtons.Down),
        justDown(GameButtons.Attack),
        justDown(GameButtons.Use),
        justDown(GameButtons.FireBow),
        justDown(GameButtons.CastSpell)
    )
}

