package uk.co.pogchampions.engine

import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.engine.input.GameInput


class GameEngine(private val graphics: Graphics, private val input: GameInput, private val scenes: Map<String, Scene>) {

    var activeSceneName: String = "connecting"
        private set

    private val activeScene: Scene
        get() = scenes[activeSceneName]!!

    init {
        scenes.values.forEach(Scene::preload)
    }

    fun update(timestamp: Double) {
        input.update()
        activeScene.update(timestamp)
        UiInput.update()
    }

    fun render() {
        activeScene.render(graphics)
    }

    fun moveToScene(sceneName: String) {
        activeSceneName = sceneName
    }
}