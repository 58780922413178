package uk.co.pogchampions.common.map

import uk.co.pogchampions.common.engine.collision.CollisionRect
import uk.co.pogchampions.common.engine.renderable.MapRenderable
import uk.co.pogchampions.common.engine.renderable.Renderable

class JsonPogChampionMap(override var name: String) : PogChampionMap {
    private var layers: List<MapRenderable> = emptyList()
    override var mapObjects: List<MapObjects> = emptyList()
    override var northernMap: String? = null
    override var southernMap: String? = null
    override var easternMap: String? = null
    override var westernMap: String? = null
    override var onMapLoaded: ((PogChampionMap) -> Unit)? = {}
        set(value) {
            if (layers.isNotEmpty()) {
                value?.invoke(this)
            }
            field = value
        }

    private val tempCollisionRect = CollisionRect(0.0, 0.0, 64.0, 64.0)

    fun populate(layers: List<MapRenderable>, mapObjects: List<MapObjects>, northernMap: String?, southernMap: String?, easternMap: String?, westernMap: String?) {
        this.layers = layers
        this.mapObjects = mapObjects
        this.northernMap = northernMap
        this.southernMap = southernMap
        this.easternMap = easternMap
        this.westernMap = westernMap
        onMapLoaded?.invoke(this)
    }

    override fun backgroundLayers(): List<Renderable> {
        return if (layers.isEmpty()) emptyList() else layers.subList(0, 3)
    }

    override fun foregroundLayers(): List<Renderable> {
        return if (layers.isEmpty()) emptyList() else  layers.drop(3)
    }

    override fun collides(collisionRect: CollisionRect): Boolean {
        if (layers.size < 3) {
            return false
        }

        val tx = (collisionRect.x / 64).toInt()
        val ty = (collisionRect.y / 64).toInt()
        val mapTile = tempCollisionRect

        for (tileX in tx until tx + 3) {
            for (tileY in ty until ty + 3) {
                if (tileX in 0..31 && tileY in 0 .. 31) {
                  if (layers[2].tileAt(tileX, tileY) != 0) {
                      mapTile.x = tileX * 64.0
                      mapTile.y = tileY * 64.0

                      if (tempCollisionRect.collidesWith(collisionRect)) {
                          return true
                      }
                  }
                }
            }
        }

        return false
    }

    override fun collides(x: Double, y: Double): Boolean {
        if (x < 0 || x > 2048 || y < 0 || y > 2048) {
            return false
        }
        return layers[2].tileAt(x.toInt() / 64, y.toInt() / 64) != 0
    }
}