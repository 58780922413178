package uk.co.pogchampions.common.map

import uk.co.pogchampions.common.actor.ActorDirection
import uk.co.pogchampions.common.actor.ActorState

sealed class MapEvent {
    data class MapChangeEvent(val forPlayer: Int, val newMap: String) : MapEvent()
    data class DialogueEvent(val forPlayer: Int, val dialogue: String, val portrait: String?) : MapEvent()
    data class SpawnActorEvent(val scriptFile: String, val x: Double, val y: Double, val direction: ActorDirection, val state: ActorState) : MapEvent()
    data class DestroyEntity(val actorId: Int) : MapEvent()
    data class IncrementPoggerinos(val playerId: Int): MapEvent()
}