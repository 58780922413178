package uk.co.pogchampions.common.engine.loaders

import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import uk.co.pogchampions.common.actor.ActorDirection
import uk.co.pogchampions.common.actor.ActorState
import uk.co.pogchampions.common.data.LoopMode
import uk.co.pogchampions.common.data.ResourceLoader
import uk.co.pogchampions.common.data.SpriteDescription
import uk.co.pogchampions.common.engine.Image
import uk.co.pogchampions.common.engine.renderable.Sprite

data class SpriteList(val spriteMap: Map<ActorState, Map<ActorDirection, Sprite>>)

object SpriteLoader {

    private val spriteListCache = mutableMapOf<String, SpriteDescription>()

    fun load(url: String, success: (SpriteList) -> Unit) {
        if (spriteListCache.contains(url)) {
            success(generateSpriteList(spriteListCache[url]!!))
        } else {
            ResourceLoader.loadResource(url) {
                val description: SpriteDescription = Json.decodeFromString(it)
                spriteListCache[url] = description
                success(generateSpriteList(description))
            }
        }
    }

    private fun generateSpriteList(description: SpriteDescription): SpriteList {
        val spriteImage = Image(description.image)
        val frameMap = description.frames.associate { frames ->
            frames.state to buildMap {
                if (frames.up.isNotEmpty()) {
                    put(
                        ActorDirection.Up, createSpriteInstance(
                            spriteImage,
                            description,
                            frames.up,
                            frames.loopMode
                        )
                    )
                }
                if (frames.down.isNotEmpty()) {
                    put(
                        ActorDirection.Down, createSpriteInstance(
                            spriteImage,
                            description,
                            frames.down,
                            frames.loopMode
                        )
                    )
                }
                if (frames.left.isNotEmpty()) {
                    put(
                        ActorDirection.Left, createSpriteInstance(
                            spriteImage,
                            description,
                            frames.left,
                            frames.loopMode
                        )
                    )
                }
                if (frames.right.isNotEmpty()) {
                    put(
                        ActorDirection.Right, createSpriteInstance(
                            spriteImage,
                            description,
                            frames.right,
                            frames.loopMode
                        )
                    )
                }

                if (frames.downLeft.isNotEmpty()) {
                    put(
                        ActorDirection.DownLeft, createSpriteInstance(
                            spriteImage,
                            description,
                            frames.downLeft,
                            frames.loopMode
                        )
                    )
                }
                if (frames.downRight.isNotEmpty()) {
                    put(
                        ActorDirection.DownRight, createSpriteInstance(
                            spriteImage,
                            description,
                            frames.downRight,
                            frames.loopMode
                        )
                    )
                }

                if (frames.upLeft.isNotEmpty()) {
                    put(
                        ActorDirection.UpLeft, createSpriteInstance(
                            spriteImage,
                            description,
                            frames.upLeft,
                            frames.loopMode
                        )
                    )
                }
                if (frames.upRight.isNotEmpty()) {
                    put(
                        ActorDirection.UpRight, createSpriteInstance(
                            spriteImage,
                            description,
                            frames.upRight,
                            frames.loopMode
                        )
                    )
                }
            }
        }
        return SpriteList(frameMap)
    }

    private fun createSpriteInstance(
        spriteImage: Image,
        description: SpriteDescription,
        frames: Array<Int>,
        loopMode: LoopMode
    ) = Sprite(
        spriteImage,
        0.0,
        0.0,
        description.cellWidth,
        description.cellHeight,
        frames,
        4
    ).apply {
        this.loopMode = loopMode
    }
}