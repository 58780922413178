package uk.co.pogchampions.game.scenes

import org.w3c.dom.events.KeyboardEvent
import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.engine.Scene
import uk.co.pogchampions.common.engine.renderable.RectRenderable
import uk.co.pogchampions.common.engine.renderable.Renderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable
import uk.co.pogchampions.engine.UiInput
import uk.co.pogchampions.game.components.Button
import uk.co.pogchampions.game.components.TextInput


class SignInScene(private val onSignInRequest: (String, String) -> Unit) : Scene {

    private val backgroundRenderables: List<Renderable> = listOf(
        RectRenderable("#333", 0.0, 0.0, 1920.0, 1080.0),
        RectRenderable("#666", 200.0, 100.0, 1520.0, 880.0),
        TextRenderable("#fff", "Pog Champions", 1920.0 / 2.0, 200.0).also {
            it.dropShadowed = true
            it.centreAligned = true
            it.fontSize = 80
        },
        TextRenderable(
            "#fff",
            "Pog Champions is pre-alpha and is unstable. Any game progress will likely",
            250.0,
            300.0
        ),
        TextRenderable("#fff", "be lost before the full 1.0 release!!!", 250.0, 340.0),
        TextRenderable("#fcb73f", "Username", 380.0, 420.0),
        TextRenderable("#fcb73f", "Password", 380.0, 500.0),
    )

    private val signInButton = Button((1920 - 350) / 2.0, 1080.0 - 300, "Sign In") {
        onSignInRequest(usernameTextInput.text, passwordTextInput.text)
    }

    private val usernameTextInput = TextInput(x = 600.0, y = 385.0)
    private val passwordTextInput = TextInput(secure = true, x = 600.0, y = 460.0)

    init {
        usernameTextInput.grantFocus()
    }

    private val foregroundRenderables: List<Renderable> = listOf(
        usernameTextInput, passwordTextInput, signInButton
    )

    override fun update(timestamp: Double) {

        UiInput.keyJustPressed()?.let { key ->
            if (usernameTextInput.isFocused()) {
                if (key == '\t'.code || key == 13) {
                    usernameTextInput.removeFocus()
                    passwordTextInput.grantFocus()
                } else {
                    usernameTextInput.handleKeyInput(key)
                }
            } else if (passwordTextInput.isFocused()) {
                if (key == '\t'.code) {
                    passwordTextInput.removeFocus()
                    usernameTextInput.grantFocus()
                } else if (key == 13) {
                    onSignInRequest(usernameTextInput.text, passwordTextInput.text)
                } else {
                    passwordTextInput.handleKeyInput(key)
                }
            }
        }

        usernameTextInput.update()
        passwordTextInput.update()
        signInButton.update()
    }

    override fun render(graphics: Graphics) {
        backgroundRenderables.forEach { it.render(graphics) }
        foregroundRenderables.forEach { it.render(graphics) }
    }
}