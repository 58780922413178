package uk.co.pogchampions.engine.input

import kotlinx.browser.window
import org.w3c.dom.events.EventListener
import org.w3c.dom.events.KeyboardEvent

class WindowGameInput : GameInput {
    companion object {
        var paused = false
    }

    data class GameInputState(var up: Boolean, var down: Boolean, var left: Boolean, var right: Boolean, var attack: Boolean, var use: Boolean, var fireBow: Boolean, var castSpell: Boolean) {
        fun copyFrom(gameInputState: GameInputState) {
            up = gameInputState.up
            down = gameInputState.down
            left = gameInputState.left
            right = gameInputState.right
            attack = gameInputState.attack
            use = gameInputState.use
            fireBow = gameInputState.fireBow
            castSpell = gameInputState.castSpell
        }
    }

    var pendingState: GameInputState = GameInputState(false, false, false, false, false, false, false, false)
    var currentState: GameInputState = GameInputState(false, false, false, false, false, false, false, false)
    var lastState:    GameInputState = GameInputState(false, false, false, false, false, false, false, false)

    init {
        window.addEventListener("keydown", EventListener {
            if (!paused) {
                when ((it as KeyboardEvent).key) {
                    "w" -> pendingState.up = true
                    "s" -> pendingState.down = true
                    "a" -> pendingState.left = true
                    "d" -> pendingState.right = true
                    "e" -> pendingState.use = true
                    " " -> pendingState.attack = true
                    "f" -> pendingState.fireBow = true
                    "g" -> pendingState.castSpell = true
                }
            } else {
                pendingState.up = false
                pendingState.down = false
                pendingState.left = false
                pendingState.right = false
                pendingState.attack = false
                pendingState.use = false
                pendingState.fireBow = false
                pendingState.castSpell = false
            }
        })

        window.addEventListener("keyup", EventListener {
            when ((it as KeyboardEvent).key) {
                "w" -> pendingState.up = false
                "s" -> pendingState.down = false
                "a" -> pendingState.left = false
                "d" -> pendingState.right = false
                "e" -> pendingState.use = false
                " " -> pendingState.attack = false
                "f" -> pendingState.fireBow = false
                "g" -> pendingState.castSpell = false
            }
        })
    }

    override fun update() {
        lastState.copyFrom(currentState)
        currentState.copyFrom(pendingState)
    }

    override fun justDown(button: GameButtons) = when (button) {
        GameButtons.Up -> currentState.up && !lastState.up
        GameButtons.Down -> currentState.down && !lastState.down
        GameButtons.Left -> currentState.left && !lastState.left
        GameButtons.Right -> currentState.right && !lastState.right
        GameButtons.Attack -> currentState.attack && !lastState.attack
        GameButtons.Use -> currentState.use && !lastState.use
        GameButtons.FireBow -> currentState.fireBow && !lastState.fireBow
        GameButtons.CastSpell -> currentState.castSpell && !lastState.castSpell
    }

    override fun justUp(button: GameButtons) = when (button) {
        GameButtons.Up -> !currentState.up && lastState.up
        GameButtons.Down -> !currentState.down && lastState.down
        GameButtons.Left -> !currentState.left && lastState.left
        GameButtons.Right -> !currentState.right && lastState.right
        GameButtons.Attack -> !currentState.attack && lastState.attack
        GameButtons.Use -> !currentState.use && lastState.use
        GameButtons.FireBow -> !currentState.fireBow && lastState.fireBow
        GameButtons.CastSpell -> !currentState.castSpell && lastState.castSpell
    }

    override fun isDown(button: GameButtons) = when (button) {
        GameButtons.Up -> currentState.up
        GameButtons.Down -> currentState.down
        GameButtons.Left -> currentState.left
        GameButtons.Right -> currentState.right
        GameButtons.Attack -> currentState.attack
        GameButtons.Use -> currentState.use
        GameButtons.FireBow -> currentState.fireBow
        GameButtons.CastSpell -> currentState.castSpell
    }

    override fun isUp(button: GameButtons) = !isDown(button)
}