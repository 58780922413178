package uk.co.pogchampions.common.dto

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class PlayerInputState(
    val left: Boolean,
    val right: Boolean,
    val up: Boolean,
    val down: Boolean,
    val attack: Boolean,
    val use: Boolean,
    val fireBow: Boolean,
    val castSpell: Boolean
) {
    @Transient
    val upLeft = up && left
    @Transient
    val upRight = up && right
    @Transient
    val downLeft = down && left
    @Transient
    val downRight = down && right

    fun toRemotePlayerInputState(id: Int): RemotePlayerInputState {
        return RemotePlayerInputState(id, left, right, up, down, attack, use, fireBow, castSpell)
    }
}

data class RemotePlayerInputState(
    val id: Int,
    val left: Boolean,
    val right: Boolean,
    val up: Boolean,
    val down: Boolean,
    val attack: Boolean,
    val use: Boolean,
    val fireBow: Boolean,
    val castSpell: Boolean
) {
    val upLeft = up && left
    val upRight = up && right
    val downLeft = down && left
    val downRight = down && right

    fun isAttemptingToMove() = left || right || up || down
}