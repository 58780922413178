package uk.co.pogchampions.common.dto

import kotlinx.serialization.Serializable
import uk.co.pogchampions.common.actor.ActorDirection
import uk.co.pogchampions.common.actor.ActorState
import uk.co.pogchampions.common.engine.PlayerProfile

@Serializable
data class ActorMetadata(val name: String, val health: Int, val maxHealth: Int, val poggerinos: Int) {
    companion object {
        fun fromPlayerProfile(playerProfile: PlayerProfile): ActorMetadata {
            return ActorMetadata(playerProfile.name, playerProfile.health, playerProfile.maxHealth, playerProfile.poggerinos)
        }
    }
}

@Serializable
data class RemoteActorState(
    val id: Int,
    val x: Double,
    val y: Double,
    val direction: ActorDirection,
    val state: ActorState,
    val sprite: String,
    val actorMetadata: ActorMetadata? = null
) {
    fun canFreelyMove() = state != ActorState.Attacking && state != ActorState.Hurting
}