package uk.co.pogchampions.common.dto

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.*

typealias TileMapObjectProperty = TileMapProperty

@Serializable
data class TileMapObject(
    val id: Int,
    val name: String,
    val type: String,
    val x: Double,
    val y: Double,
    val width: Double,
    val height: Double,
    val properties: List<TileMapObjectProperty> = emptyList(),
) {
    @Suppress("UNCHECKED_CAST")
    operator fun <T> get(name: String): T {
        return properties.firstOrNull { it.name == name }?.value as T
    }
}

@Serializable
data class TiledMapLayer(
    val data: List<Int> = emptyList(),
    val objects: List<TileMapObject> = emptyList(),
    val width: Int = 0,
    val height: Int = 0,
    val type: String,
    val name: String,
)

@Serializable
data class TiledSet(
    val firstgid: Int,
    val source: String,
)

@Serializable(with = TileMapPropertySerializer::class)
data class TileMapProperty(
    val name: String,
    val type: String,
    @Contextual val value: Any
)

@Serializable
data class TiledMap(
    val layers: List<TiledMapLayer>,
    val tilesets: List<TiledSet> = emptyList(),
    val properties: List<TileMapProperty> = emptyList(),
)

object TileMapPropertySerializer : KSerializer<TileMapProperty> {
    override val descriptor: SerialDescriptor = buildClassSerialDescriptor("TileMapProperty") {
        element("name", serialDescriptor<String>())
        element("type", serialDescriptor<String>())
        element("value", buildClassSerialDescriptor("Any"))
    }

    @Suppress("UNCHECKED_CAST")
    private val dataTypeSerializers: Map<String, KSerializer<Any>> =
        mapOf(
            "string" to serializer<String>(),
            "int" to serializer<Int>(),
        ).mapValues { (_, v) -> v as KSerializer<Any> }

    private fun getPayloadSerializer(dataType: String): KSerializer<Any> = dataTypeSerializers[dataType]
        ?: throw SerializationException("Serializer for class $dataType is not registered in PacketSerializer")


    @OptIn(ExperimentalSerializationApi::class)
    override fun deserialize(decoder: Decoder): TileMapProperty = decoder.decodeStructure(descriptor) {
        if (decodeSequentially()) {
            val name = decodeStringElement(descriptor, 0)
            val type = decodeStringElement(descriptor, 1)
            val value = decodeSerializableElement(descriptor, 2, getPayloadSerializer(name))
            TileMapProperty(name, type, value)
        } else {
            require(decodeElementIndex(descriptor) == 0) { "name field should precede payload field" }
            val name = decodeStringElement(descriptor, 0)
            decodeElementIndex(descriptor)
            val type = decodeStringElement(descriptor, 1)
            val value = when (val index = decodeElementIndex(descriptor)) {
                2 -> decodeSerializableElement(descriptor, 2, getPayloadSerializer(type))
                CompositeDecoder.DECODE_DONE -> throw SerializationException("payload field is missing")
                else -> error("Unexpected index: $index")
            }
            TileMapProperty(name, type, value)
        }
    }

    override fun serialize(encoder: Encoder, value: TileMapProperty) {
        encoder.encodeStructure(descriptor) {
            encodeStringElement(descriptor, 0, value.name)
            encodeStringElement(descriptor, 1, value.type)
            encodeSerializableElement(descriptor, 2, getPayloadSerializer(value.type), value.value)
        }
    }
}