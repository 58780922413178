package uk.co.pogchampions.engine.dom

import kotlinx.browser.window
import org.w3c.dom.CanvasRenderingContext2D
import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.Image

class CanvasGraphics(private val context: CanvasRenderingContext2D) : Graphics {

    init {
        context.imageSmoothingEnabled = false
    }

    override fun updateBounds() {
        context.canvas.width = window.innerWidth
        context.canvas.height = window.innerHeight
        context.imageSmoothingEnabled = false
    }

    override fun width(): Int = context.canvas.width
    override fun height(): Int = context.canvas.height

    override fun fillRect(colour: String, x: Double, y: Double, w: Double, h: Double) {
        context.fillStyle = colour
        context.fillRect(x, y, w, h)
        context.fill()
    }

    override fun drawImage(
        image: Image,
        x: Double,
        y: Double,
        w: Double,
        h: Double,
        cx: Double,
        cy: Double,
        cw: Double,
        ch: Double
    ) {
        context.drawImage(image.image, cx, cy, cw, ch, x, y, w, h)
    }

    override fun drawText(colour: String, fontSize: Int, text: String, x: Double, y: Double) {
        context.fillStyle = colour
        context.font = "${fontSize}px FutilePro"
        context.fillText(text, x, y)
    }

    override fun measureText(text: String, fontSize: Int): Double {
        context.font = "${fontSize}px FutilePro"
        return context.measureText(text).width
    }

    override fun clipRect(x: Double, y: Double, w: Double, h: Double, drawOps: Graphics.() -> Unit) {
        context.save()
        context.rect(x, y, w, h)
        context.clip()
        drawOps()
        context.restore()
    }

    override fun offset(x: Double, y: Double, ops: Graphics.() -> Unit) {
        context.save()
        context.translate(x, y)
        ops()
        context.restore()
    }

    override fun withAlpha(alpha: Double, operation: Graphics.() -> Unit) {
        context.globalAlpha = alpha
        operation()
        context.globalAlpha = 1.0
    }
}