package uk.co.pogchampions.engine

import kotlinx.browser.window
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.EventListener
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent

object UiInput {
    fun update() {
        lastDown = mouseDown
        mouseDown = false
        keyDown = null
    }

    fun mouseJustDown(): Boolean = !lastDown && mouseDown
    fun mouseJustUp(): Boolean = lastDown && !mouseDown
    fun mouseIn(xRange: IntRange, yRange: IntRange): Boolean {
        return mouseX in xRange && mouseY in yRange
    }

    fun keyJustPressed(): Int? {
        return keyDown
    }

    fun attachListeners(element: HTMLElement) {
        element.addEventListener("mousedown", EventListener {
            mouseDown = true
        })

        element.addEventListener("mousemove", EventListener { event ->
            mouseX = (event as MouseEvent).offsetX.toInt()
            mouseY = event.offsetY.toInt()
        })

        window.addEventListener("keydown", EventListener { event ->
            keyDown = (event as KeyboardEvent).keyCode
            if (event.ctrlKey == false) {
                event.preventDefault()
            }
        })
    }

    var mouseX: Int = 0
    var mouseY: Int = 0

    var mouseDown: Boolean = false
    var lastDown: Boolean = false
    var keyDown: Int? = null
}