package uk.co.pogchampions.game.comms.fake

import uk.co.pogchampions.common.engine.Player
import uk.co.pogchampions.common.engine.PlayerProfile
import uk.co.pogchampions.common.engine.PlayerSource

class FakePlayerSource : PlayerSource {
    private val players = mutableListOf<Player>()
    private lateinit var playerLeft: (Player) -> PlayerProfile
    private lateinit var playerAvailable: (Player) -> Unit

    override fun onPlayerAvailable(playerAvailable: (Player) -> Unit) {
        this.playerAvailable = playerAvailable
    }

    override fun onPlayerLeft(playerLeft: (Player) -> PlayerProfile) {
        this.playerLeft = playerLeft
    }

    fun simulateNewPlayer(onMapChange: (String) -> Unit, onDialogue: (String, String?) -> Unit): FakePlayer {
        val player = FakePlayer(
            PlayerProfile(
            players.size + 1,
            "Player",
            "sprites/Player-Archer-Green.json",
            10,
            10,
            123,
            "map/A1.tmj",
            964.0,
            1800.0
        ),
            {
                onMapChange(it)
            },
            { dialogue, portrait ->
                onDialogue(dialogue, portrait)
            }
        )
        players.add(player)
        this.playerAvailable(player)
        return player
    }
}
