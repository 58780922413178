package uk.co.pogchampions.game.scenes

import kotlinx.browser.document
import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.Image
import uk.co.pogchampions.common.engine.loaders.SpriteLoader
import uk.co.pogchampions.engine.Scene
import uk.co.pogchampions.common.engine.renderable.RectRenderable
import uk.co.pogchampions.common.engine.renderable.Renderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable
import uk.co.pogchampions.common.logging.GameLog
import uk.co.pogchampions.common.scripting.lua.LuaScriptCache
import uk.co.pogchampions.engine.UiInput
import uk.co.pogchampions.game.components.Button

class SplashRenderable : Renderable {
    private val rect = RectRenderable("#444", 0.0, 0.0, 1920.0, 1080.0)

    override fun render(graphics: Graphics) {
        rect.render(graphics)
    }
}

class PogChampionsSplash(private val onStart: () -> Unit) : Scene {
    private val splashRenderable = SplashRenderable()
    private val pogChampionsTitle = TextRenderable("#fff", "Pog Champions", 1920.0 / 2.0, 100.0).apply {
        centreAligned = true
        dropShadowed = true
        fontSize = 100
    }

    private val startButton = Button(1920.0 / 2, 900.0, "Play Pog Champions") {
        onStart()
    }

    override fun preload() {
        super.preload()
        if (document.location.toString().contains("localhost")) {
            LuaScriptCache.loadScript("scripts/Arrow.lua")
            LuaScriptCache.loadScript("scripts/GreasedUpDeafGuy.lua")
            LuaScriptCache.loadScript("scripts/Poggerino.lua")
            LuaScriptCache.loadScript("scripts/Slime.lua")
            listOf(
                "sprites/Archer-Green.png",
                "sprites/Archer-Purple.png",
                "sprites/Mage-Cyan.png",
                "sprites/Mage-Red.png",
                "sprites/Soldier-Blue.png",
                "sprites/Soldier-Red.png",
                "sprites/Soldier-Yellow.png",
                "sprites/Warrior-Blue.png",
                "sprites/Warrior-Red.png"
            ).map {
                Image(it)
            }

            listOf(
                "sprites/Player-Archer-Green.json",
                "sprites/Player-Archer-Purple.json",
                "sprites/Player-Mage-Cyan.json",
                "sprites/Player-Mage-Red.json",
                "sprites/Player-Soldier-Blue.json",
                "sprites/Player-Soldier-Red.json",
                "sprites/Player-Soldier-Yellow.json",
                "sprites/Player-Warrior-Blue.json",
                "sprites/Player-Warrior-Red.json"
            ).forEach { sprite ->
                SpriteLoader.load(sprite) {}
            }
        }
    }

    override fun update(timestamp: Double) {
        startButton.update()

        if (UiInput.keyJustPressed() == 13) {
            onStart()
        }
    }

    override fun render(graphics: Graphics) {
        splashRenderable.render(graphics)
        startButton.render(graphics)
        pogChampionsTitle.render(graphics)
    }
}