package uk.co.pogchampions.common.engine.loaders

import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import uk.co.pogchampions.common.data.ResourceLoader
import uk.co.pogchampions.common.dto.TiledMap
import uk.co.pogchampions.common.engine.Image
import uk.co.pogchampions.common.engine.renderable.MapRenderable
import uk.co.pogchampions.common.map.MapObjects
import uk.co.pogchampions.common.map.JsonPogChampionMap

object ResourcePogChampionMapLoader : PogChampionMapLoader {

    private val json = Json { ignoreUnknownKeys = true }

    override fun loadMap(name: String): JsonPogChampionMap {
        val pogChampionMap = JsonPogChampionMap(name)
        ResourceLoader.loadResource(name) {
            createMap(pogChampionMap, json.decodeFromString<TiledMap>(it))
        }
        return pogChampionMap
    }

    private fun createMap(map: JsonPogChampionMap, mapData: TiledMap) {
        val tileSets =
            mapData.tilesets.associate { it.firstgid to Image("map/Texture/${it.source.replace(".tsx", ".png")}") }

        val northernMap = mapData.properties.find { it.name == "northernMap" }?.value.toString()
        val easternMap = mapData.properties.find { it.name == "easternMap" }?.value.toString()
        val southernMap = mapData.properties.find { it.name == "southernMap" }?.value.toString()
        val westernMap = mapData.properties.find { it.name == "westernMap" }?.value.toString()

        val layers = mapData.layers.filter { it.type == "tilelayer" }.map {
            MapRenderable(it.width, it.height, it.data, tileSets)
        }

        val mapObjects = mapData.layers.filter { it.type == "objectgroup" }.flatMap {
            it.objects.mapNotNull { tileMapObject ->
                when (tileMapObject.type) {
                    "ActorScript" -> {
                        MapObjects.ActorScriptObject(
                            tileMapObject.x * 2,
                            tileMapObject.y * 2,
                            tileMapObject["scriptFile"]
                        )
                    }

                    "DialogueSource" -> {
                        MapObjects.DialogueSource(
                            tileMapObject.x * 2,
                            tileMapObject.y * 2,
                            tileMapObject.width * 2,
                            tileMapObject.height * 2,
                            tileMapObject["dialogue"],
                            tileMapObject["portrait"],
                        )
                    }

                    "Teleporter" -> {
                        MapObjects.TeleporterObject(
                            tileMapObject.x * 2,
                            tileMapObject.y * 2,
                            tileMapObject.width * 2,
                            tileMapObject.height * 2,
                            tileMapObject.properties.first { property -> property.name == "target" }.value.toString(),
                            tileMapObject.properties.first { property -> property.name == "posX" }.value.toString()
                                .toInt(),
                            tileMapObject.properties.first { property -> property.name == "posY" }.value.toString()
                                .toInt(),
                        )
                    }
                    else -> null
                }
            }
        }
        map.populate(layers, mapObjects, northernMap, southernMap, easternMap, westernMap)
    }
}