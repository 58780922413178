package uk.co.pogchampions.common.actor;

enum class ActorDirection {
    Up,
    UpRight,
    UpLeft,
    Down,
    DownRight,
    DownLeft,
    Left,
    Right,;

    fun isUpish(): Boolean {
        return this == Up || this == UpRight || this == UpLeft
    }

    fun isDownish(): Boolean {
        return this == Down || this == DownRight || this == DownLeft
    }

    fun isLeftish(): Boolean {
        return this == Left || this == DownLeft || this == UpLeft
    }

    fun isRightish(): Boolean {
        return this == Right || this == DownRight || this == UpRight
    }
}
