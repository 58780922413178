package uk.co.pogchampions.common.engine.renderable

import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.Image

class MapRenderable(
    private val width: Int,
    private val height: Int,
    private val tiles: List<Int>,
    private val tileMaps: Map<Int, Image>
) : Renderable {
    override fun render(graphics: Graphics) {
        for (y in 0 until height) {
            for (x in 0 until width) {
                val unNormalisedTileIndex = tiles[y * width + x]

                if (unNormalisedTileIndex > 0) {
                    val image = tileMapForTile(unNormalisedTileIndex)
                    val tileIndex = normalisedTileIndex(unNormalisedTileIndex)
                    graphics.drawImage(
                        image,
                        x * 63.99,
                        y * 63.99,
                        64.0,
                        64.0,
                        image.tileX(tileIndex, 32).toDouble(),
                        image.tileY(tileIndex, 32).toDouble(),
                        32.0,
                        32.0
                    )
                }
            }
        }
    }

    fun tileAt(mapX: Int, mapY: Int): Int {
        val index = mapX + (mapY * width)
        return if (index in tiles.indices) tiles[index] else 0
    }

    private fun tileMapForTile(tile: Int) = tileMaps[tileMaps.keys.sortedDescending().first { it <= tile }]!!

    private fun normalisedTileIndex(tile: Int) = tile - tileMaps.keys.sortedDescending().first { it <= tile }
}