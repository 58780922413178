package uk.co.pogchampions.game.components

import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.engine.UiInput
import uk.co.pogchampions.common.engine.renderable.RectRenderable
import uk.co.pogchampions.common.engine.renderable.Renderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable

interface UiElement {
    fun handleKeyInput(key: Int)
    fun removeFocus()
    fun grantFocus()
    fun isFocused(): Boolean
}

class TextInput(private val secure: Boolean = false, private val x: Double, private val y: Double) : Renderable, UiElement {

    private val border = RectRenderable("#fff", x, y, 300.0, 50.0)
    private val body = RectRenderable("#333", x + 4.0, y + 4.0, 292.0, 42.0)
    private val inputText = TextRenderable("#fff", "",x+6.0, y+38.0)
    private val focusIndicator = RectRenderable("#fff", x + 10, y+6, 2.0, 38.0)

    private var focused = false
    var text = ""

    override fun render(graphics: Graphics) {
        border.render(graphics)
        body.render(graphics)

        if (focused) {
            focusIndicator.render(graphics)
        }

        inputText.render(graphics)
    }

    fun update() {
        inputText.text = if (secure) buildString { repeat(text.length) { this.append("*")} } else text
        focusIndicator.x = x + 10 + inputText.measuredWidth
    }

    override fun handleKeyInput(key: Int) {
        if (key == 8) {
            text = text.dropLast(1)
        } else {
            if (key.toChar().isLetterOrDigit()) {
                text += key.toChar()
            }
        }
    }

    override fun removeFocus() {
        focused = false
    }

    override fun grantFocus() {
        focused = true
    }

    override fun isFocused(): Boolean {
        return focused
    }
}
