package uk.co.pogchampions.common

import uk.co.pogchampions.common.dto.GameState
import uk.co.pogchampions.common.engine.Player
import uk.co.pogchampions.common.engine.PlayerSource

class GameStateCalculator(
    private val mapSource: MapSource,
    playerSource: PlayerSource
) {

    private val players = mutableListOf<Player>()

    init {
        playerSource.onPlayerAvailable { player ->
            players.find { it.playerProfile.id == player.playerProfile.id }?.kick()
            mapSource.map(player.playerProfile.currentMap).spawnPlayer(player)
            players.add(player)
        }

        playerSource.onPlayerLeft { player ->
            val map = mapSource.mapForPlayer(player)
            val updatedProfile = map.generateProfileForPlayer(player)
            map.removePlayer(player)

            players.remove(player)
            updatedProfile
        }
    }

    fun sendStates() {
        mapSource.onAllMapsWithPlayers {
            it.updateState()
        }

        players.forEach { player ->
            val currentMap = mapSource.mapForPlayer(player)
            player.sendPlayerStates(
                GameState(
                    player.playerProfile.id,
                    currentMap.managedMap.name,
                    currentMap.playerStates(),
                    currentMap.npcStates()
                )
            )
        }
    }
}