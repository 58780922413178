package uk.co.pogchampions.common.engine.renderable

import uk.co.pogchampions.common.data.LoopMode
import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.Image
import uk.co.pogchampions.common.engine.time.GameTime

class Sprite(
    private val image: Image,
    var x: Double,
    var y: Double,
    private val cellWidth: Int,
    private val cellHeight: Int,
    private val frames: Array<Int>,
    private var scale: Int = 1
) :
    Renderable {

    var loopMode: LoopMode = LoopMode.Repeat
    private var frameAdvance = 1

    private var currentFrame: Int = 0
    private var lastFrame: Double = 0.0
    private var frameTime = 80.0

    override fun render(graphics: Graphics) {
        graphics.drawImage(
            image,
            x,
            y,
            cellWidth.toDouble() * scale,
            cellHeight.toDouble() * scale,
            frameX(),
            frameY(),
            cellWidth.toDouble(),
            cellHeight.toDouble()
        )
    }

    fun update(timestamp: Double) {
        if (timestamp > lastFrame + frameTime) {
            lastFrame = timestamp
            currentFrame += frameAdvance
            if (currentFrame > frames.size - 1) {
                when (loopMode) {
                    LoopMode.Repeat -> currentFrame = 0
                    LoopMode.Bounce -> {
                        frameAdvance = -1
                        currentFrame = frames.size - 1
                    }

                    LoopMode.OneShot -> {
                        currentFrame = frames.size - 1
                        frameAdvance = 0
                    }
                }
            } else if (currentFrame < 0) {
                currentFrame = 1
                frameAdvance = 1
            }
        }
    }

    private fun frameX(): Double {
        return (cellWidth * frames[currentFrame]).mod(image.width).toDouble()
    }

    private fun frameY(): Double {
        return (((cellWidth * frames[currentFrame]) / image.width) * cellHeight).toDouble()
    }

    fun reset() {
        lastFrame = GameTime.currentTime()
        currentFrame = 0
        frameAdvance = 1
    }
}