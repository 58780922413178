package uk.co.pogchampions.common.scripting.lua

import org.luaj.vm2.*
import org.luaj.vm2.compiler.LuaC
import org.luaj.vm2.lib.*
import uk.co.pogchampions.common.actor.ActorDirection
import uk.co.pogchampions.common.actor.ActorState
import uk.co.pogchampions.common.dto.RemoteActorState
import uk.co.pogchampions.common.logging.GameLog
import uk.co.pogchampions.common.map.MapEvent
import uk.co.pogchampions.common.map.PogChampionMap
import uk.co.pogchampions.common.scripting.ActorInteraction
import uk.co.pogchampions.common.scripting.ActorScript
import uk.co.pogchampions.common.scripting.AdditionalMath

class LuaActorScript : ActorScript {

    private var parsed = false
    lateinit var scriptInstance: LuaValue

    private val queuedInteractions = mutableMapOf<Int, MutableList<ActorInteraction>>()

    override fun parse(script: String) {
        scriptInstance = globals.load(script).call()
        parsed = true
    }

    override fun onLoad(state: RemoteActorState): RemoteActorState {
        return tableToState(scriptInstance.invokemethod("onLoad", stateToTable(state)).arg1())
    }

    override fun onUse(state: RemoteActorState, playerState: RemoteActorState) {
        queueInteraction(state.id, ActorInteraction.ActorUsed(playerState))
    }

    override fun onAttacked(state: RemoteActorState, attackingPlayer: RemoteActorState) {
        queueInteraction(state.id, ActorInteraction.ActorAttacked(attackingPlayer))
    }

    private fun queueInteraction(scriptId: Int, interaction: ActorInteraction) {
        queuedInteractions.getOrPut(scriptId) { mutableListOf() }.add(interaction)
    }

    override fun onUpdate(
        time: Double,
        nonPlayerActorState: RemoteActorState,
        playersOnMapStates: List<RemoteActorState>,
        map: PogChampionMap,
        onMapEvent: (MapEvent) -> Unit
    ): RemoteActorState {
        if (!parsed) {
            return nonPlayerActorState
        }

        pogChampionsLuaApi.onMapEvent = onMapEvent
        pogChampionsLuaApi.map = map

        val actorState = actorStates.getOrPut(nonPlayerActorState.id) {
            scriptInstance.invokemethod("createState", stateToTable(nonPlayerActorState)).arg1()
        }

        queuedInteractions[nonPlayerActorState.id]?.forEach { interaction ->
            when (interaction) {
                is ActorInteraction.ActorUsed -> {
                    GameLog.log("ActorUsed! - ${nonPlayerActorState.id} - ${interaction.playerState.id}")
                    executeScriptContextFunction(
                        "onUse",
                        time,
                        interaction,
                        nonPlayerActorState,
                        actorState
                    )
                }

                is ActorInteraction.ActorAttacked -> {
                    executeScriptContextFunction(
                        "onAttacked",
                        time,
                        interaction,
                        nonPlayerActorState,
                        actorState
                    )
                }
            }
        }
        queuedInteractions[nonPlayerActorState.id]?.clear()

        val result = scriptInstance.invokemethod(
            "onUpdate", arrayOf(
                LuaValue.valueOf(time),
                stateToTable(nonPlayerActorState),
                actorState,
                toPlayerList(playersOnMapStates)
            )
        )
        val newStateTable = result.arg1()
        actorStates[nonPlayerActorState.id] = result.arg(2)
        return tableToState(newStateTable)
    }

    private fun executeScriptContextFunction(
        contextFunction: String,
        time: Double,
        interaction: ActorInteraction,
        nonPlayerActorState: RemoteActorState,
        actorState: LuaValue
    ) {
        if (scriptInstance[contextFunction].isfunction()) {
            val result = scriptInstance.invokemethod(
                contextFunction,
                arrayOf(
                    LuaValue.valueOf(time),
                    stateToTable(interaction.playerState),
                    stateToTable(nonPlayerActorState),
                    actorState
                )
            )
            actorStates[nonPlayerActorState.id] = result.arg1()
        } else {
            GameLog.log("Attempted to use entity with no '$contextFunction' function")
        }
    }

    private fun toPlayerList(playerStates: List<RemoteActorState>) = LuaTable().apply {
        playerStates.forEachIndexed { index, state ->
            this[index + 1] = stateToTable(state)
        }
    }

    private fun stateToTable(remoteActorState: RemoteActorState) = LuaTable().apply {
        set("id", remoteActorState.id)
        set("x", remoteActorState.x)
        set("y", remoteActorState.y)
        set("state", remoteActorState.state.name)
        set("direction", remoteActorState.direction.name)
        set("sprite", remoteActorState.sprite)
    }

    private fun tableToState(luaTable: LuaValue): RemoteActorState {
        return RemoteActorState(
            luaTable["id"].toint(),
            luaTable["x"].todouble(),
            luaTable["y"].todouble(),
            ActorDirection.valueOf(luaTable["direction"].tojstring()),
            ActorState.valueOf(luaTable["state"].tojstring()),
            luaTable["sprite"].tojstring()
        )
    }

    companion object {
        private val pogChampionsLuaApi = PogChampionsLuaApi {}
        private val globals = Globals().apply {
            load(BaseLib())
            load(PackageLib())
            load(Bit32Lib())
            load(TableLib())
            load(StringLib())
            load(MathLib())
            load(CoroutineLib())
            load(AdditionalMath())

            LoadState.install(this)
            LuaC.install(this)

            load(pogChampionsLuaApi)
        }

        private val actorStates = mutableMapOf<Int, LuaValue>()
    }
}

class PogChampionsLuaApi(var onMapEvent: (MapEvent) -> Unit) : LibFunction() {
    lateinit var map: PogChampionMap

    override fun call(a: LuaValue, b: LuaValue): LuaValue {
        val pogChampionApi = LuaTable(0, 30)
        pogChampionApi["spawnActor"] = object : OneArgFunction() {
            override fun call(arg: LuaValue): LuaValue {
                onMapEvent(
                    MapEvent.SpawnActorEvent(
                        arg["script"].tojstring(),
                        arg["x"].todouble(),
                        arg["y"].todouble(),
                        ActorDirection.valueOf(arg["direction"].tojstring()),
                        ActorState.valueOf(arg["state"].tojstring())
                    )
                )
                return NIL
            }
        }
        pogChampionApi["triggerDialogue"] = object : OneArgFunction() {
            override fun call(arg: LuaValue): LuaValue {
                onMapEvent(
                    MapEvent.DialogueEvent(
                        arg["playerId"].toint(),
                        arg["dialogue"].tojstring(),
                        arg["portrait"].optjstring(null)
                    )
                )
                return NIL
            }
        }

        pogChampionApi["incrementPoggerinos"] = object : OneArgFunction() {
            override fun call(arg: LuaValue): LuaValue {
                onMapEvent(MapEvent.IncrementPoggerinos(arg.arg1().toint()))
                return NIL
            }
        }

        pogChampionApi["hitsMap"] = object : ThreeArgFunction() {
            override fun call(a: LuaValue, b: LuaValue, c: LuaValue): LuaValue {
                val collides = map.collides(
                    (a["x"].todouble() + b.optdouble(0.0)),
                    (a["y"].todouble() + c.optdouble(0.0))
                )
                return valueOf(collides)
            }
        }

        pogChampionApi["destroyEntity"] = object : OneArgFunction() {
            override fun call(arg: LuaValue): LuaValue {
                onMapEvent(MapEvent.DestroyEntity(arg.arg1().toint()))
                return NIL
            }
        }
        b["PogChampions"] = pogChampionApi
        b["package"]["loaded"]["PogChampions"] = pogChampionApi
        return pogChampionApi
    }
}


abstract class ThreeArgFunction : LibFunction() {

    override fun call(): LuaValue {
        return call(NIL, NIL, NIL)
    }

    override fun call(a: LuaValue): LuaValue {
        return call(a, NIL, NIL)
    }

    override fun call(a: LuaValue, b: LuaValue): LuaValue {
        return call(a, b, NIL)
    }

    abstract override fun call(a: LuaValue, b: LuaValue, c: LuaValue): LuaValue

    override fun invoke(args: Varargs): Varargs {
        return call(args.arg1(), args.arg(2))
    }
}
