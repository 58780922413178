package uk.co.pogchampions.common

import uk.co.pogchampions.common.engine.Player
import uk.co.pogchampions.common.engine.loaders.PogChampionMapLoader
import uk.co.pogchampions.common.engine.loaders.ResourcePogChampionMapLoader
import uk.co.pogchampions.common.engine.time.GameTime
import uk.co.pogchampions.common.engine.time.Time
import uk.co.pogchampions.common.map.PogChampionMapManager

class MapLoaderMapSource(private val mapLoader: PogChampionMapLoader = ResourcePogChampionMapLoader, private val time: Time = GameTime) : MapSource {

    private val maps = mutableMapOf<String, PogChampionMapManager>()

    override fun map(url: String): PogChampionMapManager {
        return maps.getOrPut(url) { PogChampionMapManager(mapLoader.loadMap(url), ::handleMapChange, time) }
    }

    override fun mapForPlayer(player: Player): PogChampionMapManager {
        return maps.firstNotNullOf { (_, value) -> if (value.containsPlayer(player)) value else null }
    }

    override fun onAllMapsWithPlayers(operation: (PogChampionMapManager) -> Unit) {
        maps.filter { it.value.isNotEmpty() }.values.forEach(operation)
    }

    private fun handleMapChange(player: Player, url: String, x: Double, y: Double) {
        map(url).addPlayer(player, x, y)
    }
}