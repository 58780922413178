package uk.co.pogchampions.common.scripting

import uk.co.pogchampions.common.dto.RemoteActorState
import uk.co.pogchampions.common.map.MapEvent
import uk.co.pogchampions.common.map.PogChampionMap

sealed interface ActorInteraction {
    val playerState: RemoteActorState

    data class ActorUsed(override val playerState: RemoteActorState) : ActorInteraction
    data class ActorAttacked(override val playerState: RemoteActorState) : ActorInteraction
}

interface ActorScript {
    fun parse(script: String)
    fun onUpdate(time: Double, nonPlayerActorState: RemoteActorState, playersOnMapStates: List<RemoteActorState>, map: PogChampionMap, onMapEvent: (MapEvent) -> Unit = {}): RemoteActorState
    fun onLoad(state: RemoteActorState): RemoteActorState
    fun onUse(state: RemoteActorState, playerState: RemoteActorState)
    fun onAttacked(state: RemoteActorState, attackingPlayer: RemoteActorState)
}