package uk.co.pogchampions.common.engine.renderable

import uk.co.pogchampions.common.engine.Graphics

class TextRenderable(var colour: String, var text: String, var x: Double, var y: Double) : Renderable {

    var dropShadowed: Boolean = false
    var centreAligned: Boolean = false
    var measuredWidth: Double = 0.0
    var fontSize: Int = 40

    override fun render(graphics: Graphics) {
        var drawX = x
        val drawY = y

        measuredWidth = graphics.measureText(text, fontSize)
        if (centreAligned) {
            drawX = x - measuredWidth / 2.0
        }

        if (dropShadowed) {
            graphics.withAlpha(0.5) {
                graphics.drawText("#333", fontSize, text, drawX + 5, drawY + 5)
            }
        }
        graphics.drawText(colour, fontSize, text, drawX, drawY)
    }

    fun measure(graphics: Graphics): Double {
        measuredWidth = graphics.measureText(text, fontSize)
        return measuredWidth
    }
}