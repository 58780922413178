package uk.co.pogchampions.common

interface ActorIdGenerator {
    val nextId: Int

    companion object : ActorIdGenerator {
        override val nextId: Int
            get() = (lastId++)

        private var lastId = 1000
    }
}
