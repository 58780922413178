package uk.co.pogchampions.game.scenes

import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.renderable.RectRenderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable
import uk.co.pogchampions.engine.Scene

class ConnectingScene : Scene {
    private val background = RectRenderable("#333", 0.0, 0.0, 1920.0, 1080.0)

    private val connecting = TextRenderable("#fff", "Connecting...", 1920.0/2, 1080.0/2-40).apply {
        centreAligned = true
        dropShadowed = true
        fontSize = 80
    }

    override fun update(timestamp: Double) {}

    override fun render(graphics: Graphics) {
        background.render(graphics)
        connecting.render(graphics)
    }
}