package uk.co.pogchampions.game.components

import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.engine.UiInput
import uk.co.pogchampions.common.engine.renderable.RectRenderable
import uk.co.pogchampions.common.engine.renderable.Renderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable

class Button(private val x: Double, private val y: Double, buttonText: String, private val onClick: () -> Unit) :
    Renderable {

    private val background = RectRenderable("#08f", 0.0, 0.0, 0.0, 120.0)

    private val buttonLabel = TextRenderable("#fff", buttonText, 50.0, 80.0).also {
        it.dropShadowed = true
        it.fontSize = 60
    }

    fun update() {
        val w = background.w/2.0
        val startX = (x - w).toInt()
        val endX = (x + w).toInt()

        if (UiInput.mouseIn(startX .. endX, y.toInt()..(y.toInt()+120))) {
            background.colour = "#8CF"
            if (UiInput.mouseJustUp()) {
                onClick()
            }
        } else {
            background.colour = "#08F"
        }
    }

    override fun render(graphics: Graphics) {
        background.w = buttonLabel.measure(graphics)+100.0
        graphics.offset(x-background.w/2, y) {
            background.render(graphics)
            buttonLabel.render(graphics)
        }
    }

}
