package uk.co.pogchampions.common.engine

import kotlinx.browser.document
import org.w3c.dom.HTMLImageElement

actual class Image actual constructor(url: String) {
    val image = (document.createElement("img") as HTMLImageElement).apply {
        src = url
    }

    actual val width: Int
        get() = image.width

    actual val height: Int
        get() = image.height

    actual fun tileX(tileNumber: Int, tileWidth: Int): Int {
        return (tileWidth * tileNumber).mod(width)
    }

    actual fun tileY(tileNumber: Int, tileWidth: Int): Int {
        return (((tileWidth * tileNumber) / width) * tileWidth)
    }
}