package uk.co.pogchampions.game.scenes

import uk.co.pogchampions.common.actor.ActorState
import uk.co.pogchampions.common.dto.GameState
import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.collision.CollisionRect
import uk.co.pogchampions.common.engine.loaders.ResourcePogChampionMapLoader
import uk.co.pogchampions.common.engine.loaders.SpriteList
import uk.co.pogchampions.common.engine.loaders.SpriteLoader
import uk.co.pogchampions.common.engine.renderable.Renderable
import uk.co.pogchampions.common.engine.time.GameTime
import uk.co.pogchampions.common.logging.GameLog
import uk.co.pogchampions.common.map.MapObjects
import uk.co.pogchampions.common.map.PogChampionMap
import uk.co.pogchampions.engine.Scene
import uk.co.pogchampions.engine.UiInput
import uk.co.pogchampions.engine.input.WindowGameInput
import uk.co.pogchampions.game.ActorRenderable
import uk.co.pogchampions.game.components.TextWindow
import kotlin.math.max
import kotlin.math.min

class GameScene(private var onPlayerDie: () -> Unit) : Scene {

    private var cameraX: Double = 0.0
    private var cameraY: Double = 0.0

    private var hud = Hud()

    private val actorSprites: MutableMap<Int, SpriteList> = mutableMapOf()

    private val actors: MutableMap<Int, ActorRenderable> = mutableMapOf()

    private var currentPlayer: ActorRenderable? = null

    private var map: PogChampionMap = object : PogChampionMap {
        override var name: String = ""
        override var mapObjects: List<MapObjects> = emptyList()
        override var northernMap: String? = null
        override var southernMap: String? = null
        override var easternMap: String? = null
        override var westernMap: String? = null
        override var onMapLoaded: ((PogChampionMap) -> Unit)? = {}

        override fun backgroundLayers(): List<Renderable> {
            return emptyList()
        }

        override fun foregroundLayers(): List<Renderable> {
            return emptyList()
        }

        override fun collides(collisionRect: CollisionRect): Boolean {
            TODO("Not yet implemented")
        }

        override fun collides(x: Double, y: Double): Boolean {
            return false
        }
    }

    private val background: List<List<Renderable>>
        get() = listOf(map.backgroundLayers())

    private val foreground: List<List<Renderable>>
        get() = listOf(map.foregroundLayers())

    private var textWindow: TextWindow? = null

    fun provideGameState(state: GameState) {
        val playerIds = state.playerStates.map { it.id }
        val nonPlayerIds = state.nonPlayerStates.map { it.id }

        actors.keys.filterNot { it in playerIds || it in nonPlayerIds }.forEach { actors.remove(it) }

        state.playerStates.find { it.id == state.playerId }?.let {
            hud.health = it.actorMetadata!!.health
            hud.maxHealth = it.actorMetadata!!.maxHealth
            hud.poggerinos = it.actorMetadata!!.poggerinos
        }

        (state.playerStates + state.nonPlayerStates).forEach {
            if (actorSprites[it.id] != null) {
                actors.getOrPut(it.id) { ActorRenderable(actorSprites[it.id]!!) }.update(it, GameTime.currentTime())
            } else {
                SpriteLoader.load(it.sprite) { sprite -> actorSprites[it.id] = sprite }
            }
        }

        currentPlayer = actors[state.playerId]

        if (currentPlayer?.actorState?.state == ActorState.Dying) {
            onPlayerDie()
            onPlayerDie = {}
        }
    }

    fun loadMap(map: String) {
        this.actors.clear()
        this.map = ResourcePogChampionMapLoader.loadMap(map)
    }

    override fun update(timestamp: Double) {
        textWindow?.update()
    }

    override fun render(graphics: Graphics) {
        currentPlayer?.let {
            cameraX = if (graphics.width() > 2048.0) {
                -(graphics.width() - 2048.0) / 2.0
            } else {
                min(max(0.0, it.actorState.x - graphics.width() / 2.0), 2048.0 - graphics.width())
            }

            cameraY = if (graphics.height() > 2048.0) {
                -(graphics.height() - 2048.0) / 2.0
            } else {
                min(max(0.0, it.actorState.y - graphics.height() / 2.0), 2048.0 - graphics.height())
            }
        }

        graphics.offset(-cameraX, -cameraY) {
            background.forEach { bg -> bg.forEach { it.render(graphics) } }
            actors.values.sortedBy { it.renderOrder() }.forEach { renderable ->
                renderable.render(graphics)
            }
            foreground.forEach { fg -> fg.forEach { it.render(graphics) } }
        }

        hud.render(graphics)

        textWindow?.render(graphics)

//        debugRenderCollision(graphics)
//        debugRenderCameraViewPort(graphics)
    }

    private fun debugRenderCollision(graphics: Graphics) {
        graphics.drawText(
            "#fff",
            20,
            "${UiInput.mouseX - cameraX} - ${UiInput.mouseY - cameraY} - ${
                map.collides(
                    UiInput.mouseX.toDouble() - cameraX,
                    UiInput.mouseY.toDouble() - cameraY
                )
            }",
            UiInput.mouseX.toDouble(),
            UiInput.mouseY.toDouble()
        )
    }

    private fun debugRenderCameraViewPort(graphics: Graphics) {
        graphics.fillRect("#f00", 1024.0, 224.0, 204.8, 204.8)
        graphics.fillRect(
            "#00f",
            1024.0 + cameraX / 10.0,
            224.0 + cameraY / 10.0,
            graphics.width() / 10.0,
            graphics.height() / 10.0
        )
        graphics.drawText("#fff", 20, "${cameraX} x ${cameraY}", 1024.0, 224.0 + 224)
    }

    fun displayDialog(text: String, portrait: String?) {
        WindowGameInput.paused = true
        textWindow = TextWindow(text, portrait) {
            WindowGameInput.paused = false
            textWindow = null
        }
    }
}