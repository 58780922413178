@file:OptIn(ExperimentalSerializationApi::class)

package uk.co.pogchampions.common.dto

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.descriptors.serialDescriptor
import kotlinx.serialization.encoding.*

@Serializable(with = MessageWrapperSerializer::class)
data class MessageWrapper(val messageType: String, @Contextual val payload: Any?) {
    companion object {
        fun emptyMessage(messageType: String) = MessageWrapper(messageType, null)
    }
}

@Serializer(forClass = MessageWrapper::class)
object MessageWrapperSerializer : KSerializer<MessageWrapper> {
    override val descriptor: SerialDescriptor = buildClassSerialDescriptor("MessageWrapper") {
        element("messageType", serialDescriptor<String>())
        element("payload", buildClassSerialDescriptor("Any"))
    }

    @Suppress("UNCHECKED_CAST")
    private val dataTypeSerializers: Map<String, KSerializer<Any>> =
        mapOf(
            "SIGN_IN" to serializer<SignInRequest>(),
            "MAP_CHANGE" to serializer<MapChangeIncoming>(),
            "DIALOGUE_EVENT" to serializer<DialogueEventIncoming>(),
            "PLAYER_STATES" to serializer<GameState>(),
            "PLAYER_INPUT" to serializer<PlayerInputState>(),
        ).mapValues { (_, v) -> v as KSerializer<Any> }

    private fun getPayloadSerializer(dataType: String): KSerializer<Any>? = dataTypeSerializers[dataType]

    override fun deserialize(decoder: Decoder): MessageWrapper = decoder.decodeStructure(descriptor) {
        if (decodeSequentially()) {
            val messageType = decodeStringElement(descriptor, 0)
            val value = getPayloadSerializer(messageType)?.let { decodeSerializableElement(descriptor, 1, it) }
            MessageWrapper(messageType, value)
        } else {
            require(decodeElementIndex(descriptor) == 0) { "name field should precede payload field" }
            val messageType = decodeStringElement(descriptor, 0)
            val value = when (val index = decodeElementIndex(descriptor)) {
                1 -> getPayloadSerializer(messageType)?.let { decodeSerializableElement(descriptor, 1, it) }
                CompositeDecoder.DECODE_DONE -> null
                else -> error("Unexpected index: $index")
            }
            MessageWrapper(messageType, value)
        }
    }

    override fun serialize(encoder: Encoder, value: MessageWrapper) {
        encoder.encodeStructure(descriptor) {
            encodeStringElement(descriptor, 0, value.messageType)
            getPayloadSerializer(value.messageType)?.let {
                encodeSerializableElement(descriptor, 1, it, value.payload ?: Any())
            }
        }
    }
}