package uk.co.pogchampions.game.comms

import uk.co.pogchampions.common.dto.GameState
import uk.co.pogchampions.common.dto.PlayerInputState

sealed class GameEvent {
    object ConnectionLost : GameEvent()
    object ConnectionEstablished : GameEvent()
}

interface PogChampionsComms {
    fun connect()

    fun signIn(username: String, password: String, success: () -> Unit, failure: () -> Unit)

    fun send(playerInputState: PlayerInputState)
    fun latestGameState(): GameState

    var onMapChange: (String) -> Unit
    var onDialogue: (String, String?) -> Unit
    var onGameEvent: (GameEvent) -> Unit
}