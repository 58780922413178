package uk.co.pogchampions.common.scripting

import org.luaj.vm2.LuaTable
import org.luaj.vm2.LuaValue
import org.luaj.vm2.lib.MathLib
import org.luaj.vm2.lib.TwoArgFunction
import kotlin.math.PI
import kotlin.math.atan
import kotlin.math.atan2

class AdditionalMath : TwoArgFunction() {
    override fun call(arg1: LuaValue, arg2: LuaValue): LuaValue {
        val cmath = LuaTable(0, 30)
        cmath["atan"] = object : MathLib.UnaryOp() {
            override fun call(d: Double): Double {
                return atan(d)
            }
        }
        cmath["atan2"] = object : MathLib.BinaryOp() {
            override fun call(x: Double, y: Double): Double {
                return atan2(x, y)
            }
        }

        cmath["todeg"] = object : MathLib.UnaryOp() {
            override fun call(d: Double): Double {
                return d * 180.0 / PI
            }
        }
        arg2["cmath"] = cmath
        arg2["package"]["loaded"]["cmath"] = cmath
        return cmath
    }

}