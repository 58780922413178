package uk.co.pogchampions.common.engine.collision

import uk.co.pogchampions.common.dto.RemoteActorState
import uk.co.pogchampions.common.map.MapObjects

class CollisionRect(var x: Double, var y: Double, var w: Double, var h: Double) {
    val r: Double
        get() = x + w

    val b: Double
        get() = y + h

    fun populateFromState(state: RemoteActorState, w: Double = 64.0, h: Double = 64.0) {
        x = state.x
        y = state.y
        this.w = w
        this.h = h
    }

    fun populateFromPlayer(state: RemoteActorState) {
        x = state.x + 32.0
        y = state.y + 32.0
        this.w = 64.0
        this.h = 64.0
    }

    fun collidesWith(other: CollisionRect): Boolean {
        return x < other.r &&
                r > other.x &&
                y < other.b &&
                b > other.y
    }

    companion object {
        fun fromPlayer(state: RemoteActorState): CollisionRect {
            return CollisionRect(
                x = state.x + 40.0,
                y = state.y + 64.0,
                w = 48.0,
                h = 32.0,
            )
        }

        fun fromMapObject(mapObjects: MapObjects): CollisionRect =
            CollisionRect(mapObjects.x, mapObjects.y, mapObjects.w, mapObjects.h)
    }
}