package uk.co.pogchampions.game

import uk.co.pogchampions.common.actor.ActorDirection
import uk.co.pogchampions.common.actor.ActorState
import uk.co.pogchampions.common.dto.RemoteActorState
import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.loaders.SpriteList
import uk.co.pogchampions.common.engine.renderable.Renderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable
import uk.co.pogchampions.common.engine.time.GameTime
import uk.co.pogchampions.common.logging.GameLog

class ActorRenderable(private val spriteList: SpriteList) : Renderable {
    lateinit var actorState: RemoteActorState

    private var direction: ActorDirection = ActorDirection.Down
        set(value) {
            if (field != value) {
                spriteList.spriteMap[state]!![value]!!.reset()
            }
            field = value
        }

    private var state: ActorState = ActorState.Idle
        set(value) {
            if (field == ActorState.Dying) {
                return
            } else if (field != value) {
                spriteList.spriteMap[value]!![direction]!!.reset()
            }
            field = value
        }

    fun update(actorState: RemoteActorState, timestamp: Double) {
        this.actorState = actorState
        direction = actorState.direction
        state = actorState.state
        spriteList.spriteMap[state]!![direction]!!.update(timestamp)
    }

    override fun render(graphics: Graphics) {
        graphics.offset(actorState.x, actorState.y) {
            nickNameText.text = actorState.actorMetadata?.name ?: ""
            nickNameText.x = 64.0
            nickNameText.y = -8.0
            nickNameText.render(graphics)

            spriteList.spriteMap[state]!![direction]!!.render(graphics)
        }
    }

    override fun renderOrder() = actorState.y.toInt()

    companion object {
        private val nickNameText = TextRenderable("#fff", "", 0.0, 0.0).also {
            it.dropShadowed = true
            it.centreAligned = true
            it.fontSize = 24
        }
    }
}