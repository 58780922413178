package uk.co.pogchampions.common.scripting.lua

import uk.co.pogchampions.common.GlobalScriptCache
import uk.co.pogchampions.common.engine.loaders.LuaActorScriptLoader
import uk.co.pogchampions.common.scripting.ActorScript

object LuaScriptCache : GlobalScriptCache {
    private val cache = mutableMapOf<String, ActorScript>()

    override fun loadScript(scriptFile: String): ActorScript {
        return cache.getOrPut(scriptFile) { LuaActorScriptLoader().loadScript(scriptFile) }
    }
}