package uk.co.pogchampions.common.data

import org.w3c.xhr.XMLHttpRequest

actual object ResourceLoader {
    actual fun loadResource(name: String, success: (String) -> Unit) {
        XMLHttpRequest().apply {
            onreadystatechange = {
                if (readyState == XMLHttpRequest.DONE) {
                    success(responseText)
                }
            }
            open("GET", name)
            send()
        }
    }
}