package uk.co.pogchampions.game.comms.fake

import kotlinx.browser.window
import org.w3c.dom.events.EventListener
import org.w3c.dom.events.KeyboardEvent
import uk.co.pogchampions.common.GameStateCalculator
import uk.co.pogchampions.common.MapLoaderMapSource
import uk.co.pogchampions.common.dto.GameState
import uk.co.pogchampions.common.dto.PlayerInputState
import uk.co.pogchampions.common.dto.RemotePlayerInputState
import uk.co.pogchampions.game.comms.GameEvent
import uk.co.pogchampions.game.comms.PogChampionsComms

class FakePogChampionsComms : PogChampionsComms {
    private val fakePlayerSource = FakePlayerSource()
    private val additionalPlayers = mutableListOf<FakePlayer>()

    private lateinit var stateCalculator: GameStateCalculator

    private lateinit var fakePlayer: FakePlayer

    private var pendingMap = ""

    override var onMapChange: (String) -> Unit = {
        pendingMap = it
    }
        set(value) {
            field = value
            if (pendingMap != "") {
                value(pendingMap)
            }
        }

    override var onDialogue: (String, String?) -> Unit = { _, _ -> }
    override var onGameEvent: (GameEvent) -> Unit = {}
        set(value) {
            field = value
            field(GameEvent.ConnectionEstablished)
        }

    init {
        window.addEventListener("keyup", EventListener { event ->
            when ((event as KeyboardEvent).key) {
                "+" -> addPlayer()
                "-" -> removePlayer()
            }
        })
    }

    private var latestGameState = GameState(0, "", emptyList(), emptyList())

    override fun connect() = Unit

    override fun signIn(username: String, password: String, success: () -> Unit, failure: () -> Unit) {
        stateCalculator = GameStateCalculator(MapLoaderMapSource(), fakePlayerSource)
        fakePlayer = fakePlayerSource.simulateNewPlayer(
            onMapChange = {
                onMapChange(it)
            },
            onDialogue = { dialogue, portrait ->
                onDialogue(dialogue, portrait)
            }
        )
        success()
    }

    override fun send(playerInputState: PlayerInputState) {
        fakePlayer.simulateInput(playerInputState.toRemotePlayerInputState(fakePlayer.playerProfile.id))
        additionalPlayers.forEach {
            it.simulateInput(
                RemotePlayerInputState(
                    it.playerProfile.id,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                )
            )
        }
        stateCalculator.sendStates()
        latestGameState = fakePlayer.latestGameState
    }

    override fun latestGameState() = latestGameState

    private fun addPlayer() {
        additionalPlayers += fakePlayerSource.simulateNewPlayer(onMapChange, onDialogue)
    }

    private fun removePlayer() {
//        if (playerStates.isNotEmpty()) {
//            playerMapPresence.remove(playerStates.last().id)
//            playerStates = playerStates.dropLast(1)
//        }
    }
}