package uk.co.pogchampions.common.actor

enum class ActorState {
    Idle,
    Walking,
    Attacking,
    FiringBow,
    CastingSpell,
    Hurting,
    Dying
}
