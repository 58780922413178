package uk.co.pogchampions.game.scenes

import kotlinx.browser.document
import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.renderable.RectRenderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable
import uk.co.pogchampions.engine.Scene
import uk.co.pogchampions.game.components.Button

class ConnectionErrorScene : Scene {
    private val background = RectRenderable("#333", 0.0, 0.0, 1920.0, 1080.0)
    private val text = TextRenderable("#fff", "The server is down or you couldn't connect", 1920.0/2, 1080.0/2-40).apply {
        centreAligned = true
        dropShadowed = true
        fontSize = 80
    }
    private val retryButton = Button(1920/2.0, 1080.0-200.0, "Retry") {
        document.location!!.reload()
    }

    override fun update(timestamp: Double) {
        retryButton.update()
    }

    override fun render(graphics: Graphics) {
        background.render(graphics)
        text.render(graphics)
        retryButton.render(graphics)
    }

}