package uk.co.pogchampions.game.scenes

import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.Image
import uk.co.pogchampions.common.engine.renderable.Renderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable

class Hud : Renderable {

    var health = 10
    var maxHealth = 10
    var poggerinos = 0

    private val heart = Image("hud/hearts.png")

    private val poggerinosText = TextRenderable("#fff", "Poggerinos: ", 20.0, 60.0).also {
        it.dropShadowed = true
        it.fontSize = 20
    }
    private val healthText = TextRenderable("#fff", "Health: ", 20.0, 30.0).also {
        it.dropShadowed = true
        it.fontSize = 20
    }

    override fun render(graphics: Graphics) {
        poggerinosText.text = "Poggerinos: "+poggerinos

        poggerinosText.render(graphics)
        healthText.render(graphics)

        val fullHearts = health / 2
        val halfHearts = health.mod(2)

        for (i in 0 until maxHealth/2) {
            if (i < fullHearts) {
                graphics.drawImage(heart, 96.0 + i * 32, 8.0, 32.0, 32.0, 0.0, 0.0, 32.0, 32.0)
            } else if (i < fullHearts + halfHearts) {
                graphics.drawImage(heart, 96.0 + i * 32, 8.0, 32.0, 32.0, 32.0, 0.0, 32.0, 32.0)
            } else {
                graphics.drawImage(heart, 96.0 + i * 32, 8.0, 32.0, 32.0, 64.0, 0.0, 32.0, 32.0)
            }
        }
    }
}