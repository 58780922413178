package uk.co.pogchampions.engine.input

enum class GameButtons {
    Up,
    Down,
    Left,
    Right,
    Attack,
    Use,
    FireBow,
    CastSpell
}
