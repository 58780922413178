package uk.co.pogchampions.game.comms.fake

import uk.co.pogchampions.common.dto.GameState
import uk.co.pogchampions.common.dto.RemotePlayerInputState
import uk.co.pogchampions.common.engine.Player
import uk.co.pogchampions.common.engine.PlayerProfile

class FakePlayer(
    override val playerProfile: PlayerProfile,
    val onMapChange: (String) -> Unit,
    val onDialogue: (String, String?) -> Unit
) : Player {
    var latestGameState: GameState = GameState(1, "", emptyList(), emptyList())
    private lateinit var inputCallback: (RemotePlayerInputState) -> Unit

    override fun onInput(onInput: (RemotePlayerInputState) -> Unit) {
        this.inputCallback = onInput
    }

    override fun sendPlayerStates(gameState: GameState) {
        latestGameState = gameState
    }

    override fun sendMap(mapUrl: String) {
        onMapChange(mapUrl)
    }

    override fun kick() {
        TODO("Not yet implemented")
    }

    override fun sendDialogueEvent(dialogue: String, portrait: String?) {
        onDialogue(dialogue, portrait)
    }

    fun simulateInput(input: RemotePlayerInputState) {
        inputCallback(input)
    }
}
