package uk.co.pogchampions.game.components

import uk.co.pogchampions.common.engine.Graphics
import uk.co.pogchampions.common.engine.Image
import uk.co.pogchampions.common.engine.renderable.RectRenderable
import uk.co.pogchampions.common.engine.renderable.Renderable
import uk.co.pogchampions.common.engine.renderable.TextRenderable
import uk.co.pogchampions.engine.UiInput

class TextWindow(private val text: String, val portrait: String?, private val onComplete: () -> Unit) : Renderable {

    private val window = RectRenderable("#fff3a8", 0.0, 0.0, 900.0, 300.0)

    private val image: Image? = portrait?.let { Image(portrait) }

    private var displayedText = ""

    private val textRenderables = mutableListOf<TextRenderable>()

    private fun addLine() {
        textRenderables += TextRenderable("#5a563c", "", 20.0, 50 + 36.0 * textRenderables.size).also {
            it.fontSize = 30
        }
    }

    init {
        addLine()
    }

    fun update() {
        if (UiInput.keyJustPressed() == 32 && displayedText == text) {
            onComplete()
        }

        if (displayedText.length != text.length) {
            if (textRenderables.last().measuredWidth > endOfLineSize() && displayedText.lastOrNull() == ' ') {
                addLine()
            }
            textRenderables.last().text += text[displayedText.length]
            displayedText += text[displayedText.length]
        }
    }

    private fun endOfLineSize() = if (portrait != null) 500 else 800

    override fun render(graphics: Graphics) {
        graphics.offset((graphics.width()-900.0)/2.0, graphics.height()-400.0) {
            window.render(graphics)
            image?.let {
                graphics.drawImage(it, 650.0, 20.0, 196.0, 196.0, 0.0, 0.0, 32.0, 32.0)
            }
            textRenderables.forEach { it.render(graphics) }
        }
    }
}