package uk.co.pogchampions.common.engine.loaders

import uk.co.pogchampions.common.data.ResourceLoader
import uk.co.pogchampions.common.scripting.lua.LuaActorScript

class LuaActorScriptLoader {
    fun loadScript(scriptFile: String): LuaActorScript {
        val script = LuaActorScript()
        ResourceLoader.loadResource(scriptFile) {
            script.parse(it)
        }
        return script
    }
}