package uk.co.pogchampions.common.map

import uk.co.pogchampions.common.ActorIdGenerator
import uk.co.pogchampions.common.GlobalScriptCache
import uk.co.pogchampions.common.actor.ActorDirection
import uk.co.pogchampions.common.actor.ActorState
import uk.co.pogchampions.common.dto.ActorMetadata
import uk.co.pogchampions.common.dto.RemoteActorState
import uk.co.pogchampions.common.dto.RemotePlayerInputState
import uk.co.pogchampions.common.engine.Player
import uk.co.pogchampions.common.engine.PlayerProfile
import uk.co.pogchampions.common.engine.collision.CollisionRect
import uk.co.pogchampions.common.engine.time.GameTime
import uk.co.pogchampions.common.engine.time.Time
import uk.co.pogchampions.common.scripting.NpcScriptState
import uk.co.pogchampions.common.scripting.lua.LuaScriptCache


class PogChampionMapManager(
    val managedMap: PogChampionMap,
    private val playerMapChange: (Player, String, Double, Double) -> Unit,
    private val time: Time = GameTime,
    private val actorScriptCache: GlobalScriptCache = LuaScriptCache,
    private val actorIdGenerator: ActorIdGenerator = ActorIdGenerator
) {
    private val playerStates = mutableMapOf<Int, RemoteActorState>()
    private val npcStates = mutableListOf<NpcScriptState>()

    private val players = mutableListOf<Player>()

    private val pendingInputs: MutableMap<Int, RemotePlayerInputState> = mutableMapOf()

    private val playerAttackTimes = mutableMapOf<Int, Double>()
    private val playerFireBowTimes = mutableMapOf<Int, Double>()
    private val playerCastingSpellTimes = mutableMapOf<Int, Double>()
    private val playerHurtTimes = mutableMapOf<Int, Double>()
    private val npcHurtTimes = mutableMapOf<Int, Double>()
    private val playerAttackBlock = mutableSetOf<Int>()
    private val playerHurtBlock = mutableSetOf<Int>()

    private val hurtBoxes = mutableMapOf<Int, CollisionRect>()

    init {
        managedMap.onMapLoaded = { map ->
            map.mapObjects.filterIsInstance<MapObjects.ActorScriptObject>().forEach { actorScriptObject ->
                npcStates.add(
                    NpcScriptState(
                        actorScriptCache.loadScript(actorScriptObject.scriptFile),
                        RemoteActorState(
                            actorIdGenerator.nextId,
                            actorScriptObject.x,
                            actorScriptObject.y,
                            ActorDirection.Down,
                            ActorState.Idle,
                            ""
                        )
                    )
                )
            }
        }
    }

    fun updateState() {
        pendingInputs.toMap().filterKeys { it in playerStates.keys }.forEach { (id, input) ->
            val oldState = playerStates[id]!!

            val newDirection = determineDirection(input, oldState.direction)

            val newState = if (checkForPlayerDamage(oldState)) ActorState.Hurting else calculatePlayerState(input)

            val (originalXSpeed, originalYSpeed) = calculateMovementSpeed(input)

            val (xSpeed, ySpeed) = handleMapCollision(oldState, newDirection, originalXSpeed, originalYSpeed)

            val newPlayerState = oldState.copy(
                direction = newDirection,
                state = newState,
                x = oldState.x + xSpeed,
                y = oldState.y + ySpeed
            )
            playerStates[id] = newPlayerState

            generateAttackHurtBoxes(newState, oldState, id)

            handlePlayerUse(input, id)

            handlePlayerTeleportation(id)

            handleMapWrap(newPlayerState)
        }

        updateNpcStates()
    }

    private fun handleMapWrap(playerState: RemoteActorState) {
        players.firstOrNull { it.playerProfile.id == playerState.id }?.let { player ->
            if (playerState.y < -64.0 && managedMap.northernMap != null) {
                movePlayerToOtherMap(player, managedMap.northernMap!!, playerState.x, 2048.0)
            }
            if (playerState.y > 2048.0 && managedMap.southernMap != null) {
                movePlayerToOtherMap(player, managedMap.southernMap!!, playerState.x, -63.0)
            }
            if (playerState.x < -64.0 && managedMap.westernMap != null) {
                movePlayerToOtherMap(player, managedMap.westernMap!!, 2048.0, playerState.y)
            }
            if (playerState.x > 2048.0 && managedMap.easternMap != null) {
                movePlayerToOtherMap(player, managedMap.easternMap!!, -63.0, playerState.y)
            }
        }
    }

    private fun movePlayerToOtherMap(
        player: Player,
        map: String,
        x: Double,
        y: Double
    ) {
        players.remove(player)
        playerStates.remove(player.playerProfile.id)
        playerMapChange(
            player,
            map,
            x,
            y
        )
    }

    private fun updateNpcStates() {
        val npcStatesToAdd = mutableListOf<NpcScriptState>()
        val npcStatesToRemove = mutableListOf<NpcScriptState>()
        npcStates.toList().forEach { npcState ->
            if (!isNpcHurting(npcState)) {
                val npcCollisionRect = CollisionRect.fromPlayer(npcState.state)
                hurtBoxes.entries.firstOrNull { (_, box) ->
                    box.collidesWith(npcCollisionRect)
                }?.let { (playerId, _) ->
                    npcState.attack(playerStates[playerId]!!)
                    startNpcHurt(npcState)
                }
            }

            npcState.update(time.currentTime(), playerStates.values.toList(), managedMap) {
                when (it) {
                    is MapEvent.DestroyEntity -> npcStatesToRemove.add(npcStates.find { state -> state.state.id == it.actorId }!!)
                    is MapEvent.DialogueEvent -> players.find { player -> player.playerProfile.id == it.forPlayer }
                        ?.sendDialogueEvent(it.dialogue, it.portrait)

                    is MapEvent.IncrementPoggerinos -> playerStates[it.playerId] = playerStates[it.playerId]!!.copy(
                        actorMetadata = playerStates[it.playerId]!!.actorMetadata!!.copy(poggerinos = playerStates[it.playerId]!!.actorMetadata!!.poggerinos + 1)
                    )

                    is MapEvent.MapChangeEvent -> TODO("Handle map change event $it")
                    is MapEvent.SpawnActorEvent -> {
                        npcStatesToAdd.add(
                            NpcScriptState(
                                actorScriptCache.loadScript(it.scriptFile),
                                RemoteActorState(
                                    time.currentTime().toInt(),
                                    it.x,
                                    it.y,
                                    it.direction,
                                    it.state,
                                    ""
                                )
                            )
                        )
                    }
                }
            }
        }
        npcStates.addAll(npcStatesToAdd)
        npcStates.removeAll(npcStatesToRemove)
    }

    private fun handlePlayerTeleportation(id: Int) {
        val playerHitBox = CollisionRect.fromPlayer(playerStates[id]!!)
        managedMap.mapObjects.filterIsInstance<MapObjects.TeleporterObject>().forEach {
            if (CollisionRect.fromMapObject(it).collidesWith(playerHitBox)) {
                val teleportingPlayer = players.first { player -> player.playerProfile.id == id }
                movePlayerToOtherMap(
                    teleportingPlayer,
                    it.destination,
                    it.positionX.toDouble() * 2,
                    it.positionY.toDouble() * 2
                )
            }
        }
    }

    private fun generateAttackHurtBoxes(
        newState: ActorState,
        oldState: RemoteActorState,
        id: Int
    ) {
        if (newState == ActorState.Attacking && oldState.state != ActorState.Attacking) {
            time.scheduleAfter(200) {
                hurtBoxes[id] = generateInteractionCollisionRect(playerStates[id]!!)
            }
            time.scheduleAfter(300) {
                hurtBoxes.remove(id)
            }
        }
    }

    private fun handlePlayerUse(input: RemotePlayerInputState, id: Int) {
        if (input.use) {
            val useBox = generateInteractionCollisionRect(playerStates[id]!!)
            managedMap.mapObjects.filterIsInstance<MapObjects.DialogueSource>().forEach {
                val mapCollisionRect = CollisionRect.fromMapObject(it)
                if (mapCollisionRect.collidesWith(useBox)) {
                    players.find { player -> player.playerProfile.id == id }
                        ?.sendDialogueEvent(it.dialogue, it.portrait)
                }
            }
            npcStates.forEach { targetNpc ->
                val mapCollisionRect = CollisionRect.fromPlayer(targetNpc.state)
                if (mapCollisionRect.collidesWith(useBox)) {
                    targetNpc.use(playerStates[id]!!)
                }
            }
        }
    }

    private fun checkForPlayerDamage(state: RemoteActorState): Boolean {
        val playerCollision = CollisionRect.fromPlayer(state)
        if (!isPlayerHurting(state.id) && hurtBoxes.filter { (id, _) -> id != state.id }
                .any { (_, box) -> box.collidesWith(playerCollision) }) {
            startPlayerHurt(state.id)
            return true
        }
        return false
    }

    private fun generateInteractionCollisionRect(state: RemoteActorState): CollisionRect {
        val xOffset = if (state.direction.isLeftish()) 0.0 else if (state.direction.isRightish()) 80.0 else 32.0
        val yOffset = if (state.direction.isUpish()) 0.0 else if (state.direction.isDownish()) 80.0 else 32.0
        val width = if (state.direction.isLeftish() || state.direction.isRightish()) 48.0 else 64.0
        val height = if (state.direction.isUpish() || state.direction.isDownish()) 48.0 else 64.0

        return CollisionRect(
            state.x + xOffset,
            state.y + yOffset,
            width,
            height
        )
    }

    private fun calculatePlayerState(input: RemotePlayerInputState): ActorState {
        if (isPlayerAttacking(input.id)) {
            return ActorState.Attacking
        } else if (isPlayerFiringBow(input.id)) {
            return ActorState.FiringBow
        } else if (isPlayerCastingSpell(input.id)) {
            return ActorState.CastingSpell
        } else if (isPlayerHurting(input.id)) {
            return ActorState.Hurting
        }

        return if (input.attack && playerCanStartAttack(input)) {
            startPlayerAttack(input.id)
            ActorState.Attacking
        } else if (input.fireBow && playerCanStartAttack(input)) {
            startPlayerFiringBow(input.id)
            ActorState.FiringBow
        } else if (input.castSpell && playerCanStartAttack(input)) {
            startPlayerCastingSpell(input.id)
            ActorState.CastingSpell
        } else if (input.isAttemptingToMove()) {
            ActorState.Walking
        } else {
            ActorState.Idle
        }
    }

    private fun playerCanStartAttack(input: RemotePlayerInputState) = !playerAttackBlock.contains(input.id)

    private fun isPlayerAttacking(id: Int) = playerAttackTimes.contains(id)
    private fun isPlayerFiringBow(id: Int) = playerFireBowTimes.contains(id)
    private fun isPlayerCastingSpell(id: Int) = playerCastingSpellTimes.contains(id)
    private fun isPlayerHurting(id: Int) = playerHurtTimes.contains(id)

    private fun startPlayerAttack(id: Int) {
        playerAttackTimes[id] = time.currentTime()
        playerAttackBlock.add(id)
        time.scheduleAfter(500) {
            playerAttackTimes.remove(id)
        }
        time.scheduleAfter(700) {
            playerAttackBlock.remove(id)
        }
    }

    private fun startPlayerFiringBow(id: Int) {
        playerFireBowTimes[id] = time.currentTime()
        playerAttackBlock.add(id)
        time.scheduleAfter(50) {
            val playerState = playerStates[id]!!
            val arrowScript = actorScriptCache.loadScript("scripts/Arrow.lua")

            val (xOffset, yOffset) = when (playerState.direction) {
                ActorDirection.Up -> 32.0 to 0.0
                ActorDirection.UpRight -> 64.0 to 0.0
                ActorDirection.UpLeft -> 0.0 to 0.0
                ActorDirection.Down -> 32.0 to 64.0
                ActorDirection.DownRight -> 64.0 to 64.0
                ActorDirection.DownLeft -> 0.0 to 64.0
                ActorDirection.Left -> 0.0 to 32.0
                ActorDirection.Right -> 64.0 to 32.0
            }

            npcStates.add(
                NpcScriptState(
                    arrowScript,
                    RemoteActorState(
                        time.currentTime().toInt(),
                        playerState.x + xOffset,
                        playerState.y + yOffset,
                        playerState.direction,
                        ActorState.Idle,
                        ""
                    )
                )
            )
        }
        time.scheduleAfter(300) {
            playerFireBowTimes.remove(id)
        }
        time.scheduleAfter(600) {
            playerAttackBlock.remove(id)
        }
    }

    private fun startPlayerCastingSpell(id: Int) {
        playerCastingSpellTimes[id] = time.currentTime()
        playerAttackBlock.add(id)
        time.scheduleAfter(600) {
            playerCastingSpellTimes.remove(id)
        }
        time.scheduleAfter(700) {
            playerAttackBlock.remove(id)
        }
    }

    private fun startPlayerHurt(id: Int) {
        playerHurtTimes[id] = time.currentTime()
        playerHurtBlock.add(id)
        time.scheduleAfter(500) {
            playerHurtTimes.remove(id)
        }
        time.scheduleAfter(800) {
            playerHurtBlock.remove(id)
        }
    }

    private fun startNpcHurt(npcState: NpcScriptState) {
        npcHurtTimes[npcState.state.id] = time.currentTime()
        time.scheduleAfter(200) {
            npcHurtTimes.remove(npcState.state.id)
        }
    }

    private fun isNpcHurting(npcState: NpcScriptState): Boolean = npcHurtTimes.containsKey(npcState.state.id)

    private fun handleMapCollision(
        state: RemoteActorState,
        newDirection: ActorDirection,
        originalXSpeed: Double,
        originalYSpeed: Double
    ): Pair<Double, Double> {
        val horizontalCollisionRect = CollisionRect.fromPlayer(state)
        val verticalCollisionRect = CollisionRect.fromPlayer(state)
        horizontalCollisionRect.x += originalXSpeed
        verticalCollisionRect.y += originalYSpeed

        var xSpeed = originalXSpeed
        var ySpeed = originalYSpeed

        if (managedMap.collides(horizontalCollisionRect)) {
            if (newDirection.isRightish() || newDirection.isLeftish()) {
                xSpeed = 0.0
            }
        }

        if (managedMap.collides(verticalCollisionRect)) {
            if (newDirection.isUpish() || newDirection.isDownish()) {
                ySpeed = 0.0
            }
        }

        return xSpeed to ySpeed
    }

    fun spawnPlayer(player: Player) {
        addPlayer(player, player.playerProfile.lastPosX, player.playerProfile.lastPosY)
    }

    fun addPlayer(player: Player, x: Double, y: Double) {
        players.add(player)
        player.sendMap(managedMap.name)
        playerStates[player.playerProfile.id] = RemoteActorState(
            player.playerProfile.id,
            x, y,
            ActorDirection.Down,
            ActorState.Idle,
            player.playerProfile.sprite,
            ActorMetadata.fromPlayerProfile(player.playerProfile)
        )

        player.onInput {
            pendingInputs[it.id] = it
        }
    }


    fun playerStates(): Collection<RemoteActorState> = playerStates.values.toList()
    fun npcStates(): Collection<RemoteActorState> = npcStates.map { it.state }

    fun containsPlayer(player: Player) = players.any { it.playerProfile.id == player.playerProfile.id }

    fun isNotEmpty() = players.isNotEmpty()

    private fun determineDirection(
        input: RemotePlayerInputState,
        previousDirection: ActorDirection
    ): ActorDirection {
        if (isPlayerAttacking(input.id) || isPlayerHurting(input.id) || isPlayerFiringBow(input.id) || isPlayerCastingSpell(input.id)) {
            return previousDirection
        }
        return when {
            input.upLeft -> ActorDirection.UpLeft
            input.upRight -> ActorDirection.UpRight
            input.downLeft -> ActorDirection.DownLeft
            input.downRight -> ActorDirection.DownRight
            input.up -> ActorDirection.Up
            input.down -> ActorDirection.Down
            input.left -> ActorDirection.Left
            input.right -> ActorDirection.Right
            else -> previousDirection
        }
    }

    private fun calculateMovementSpeed(input: RemotePlayerInputState): Pair<Double, Double> {
        if (isPlayerAttacking(input.id) || isPlayerHurting(input.id) || isPlayerFiringBow(input.id) || isPlayerCastingSpell(
                input.id
            )
        ) {
            return 0.0 to 0.0
        }
        val xSpeed = if (input.left) {
            -5.0
        } else if (input.right) {
            5.0
        } else {
            0.0
        }

        val ySpeed = if (input.up) {
            -5.0
        } else if (input.down) {
            5.0
        } else {
            0.0
        }

        val speedCoefficient = if (xSpeed != 0.0 && ySpeed != 0.0) 0.7 else 1.0
        return xSpeed * speedCoefficient to ySpeed * speedCoefficient
    }

    fun removePlayer(player: Player) {
        players.remove(player)
        playerStates.remove(player.playerProfile.id)
    }

    fun generateProfileForPlayer(player: Player): PlayerProfile {
        val state = playerStates[player.playerProfile.id]!!
        return player.playerProfile.copy(
            lastPosX = state.x,
            lastPosY = state.y,
            health = state.actorMetadata!!.health,
            maxHealth = state.actorMetadata.maxHealth,
            currentMap = managedMap.name,
            poggerinos = state.actorMetadata.poggerinos
        )
    }
}
